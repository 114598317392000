html,
body {
    width: 100%;
    height: 100%;
}

#root {
    width: 100%;
    height: 100%;

    main {
        background: #eaeaea;
        background-image: linear-gradient(0deg, #b1b1b1 0%, #eaeaea 100%);
        background-attachment: fixed;
        position: relative;
        width: 100%;
        min-height: calc(100% - 70px);

        &.user-signed-in {
            padding-top: 70px;
        }
    }

    header {
        a:hover {
            text-decoration: none;
        }
    }
}

a {
    text-decoration: none;
    color: #305f3c;
}

a:hover {
    text-decoration: underline;
}
